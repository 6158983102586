import { MessageIdDto, StageDto, TimezoneDto } from "./../model";
import Axios from "../index";
import qs from "qs";
import {
  ImportTokensDto,
  MoveOnboardingPollDto,
  MoveSectionDto,
  MoveSectionMessageDto,
  ProjectsDto,
  TokensDto,
  UpdateOrCreateOnboardingPollsDto,
  UpdateOrCreateProjectDto,
  UpdateOrCreateSectionDto,
  UpdateOrCreateSectionMessageDto,
  UpdateOrCreateSectionMessageOptionDto,
  UpdateOrCreateSectionMessageSettingDto,
  UpdateOrCreateSegmentDto,
  IdsDto,
  ExcelGuideDto,
  MoveSectionMessageGroupDto,
  UpdateOrCreateSectionMessageBranchDto,
  MoveSectionMessageOptionDto,
  CopyContentToAllBranchsDto,
  UpdateOrCreateConversationMessageDto,
  ChangeSectionMessageStatusDto,
  GetMessageSegmentAnalyzeDto,
  GetMessageOptionsAnalyzeDto,
  UpdateShareInfoDto,
  GetSegmentJoinsOptionsDto,
  SetProjectQuotaDto,
  ImportNlpDto,
  UpdateOrCreateTestGroupDto,
  UpdateSectionTestGroupDto,
  ComputeQuotaGroupDto,
  TestGroupAnalysisDto
} from "./model";
import { DriverDto, IdDto, ObserverDto, UuIdDto } from "../model";
import {
  getLiveMessageOptionsAnalyzeApi,
  getLiveMessageSegmentAnalyzeApi,
  getLiveTestGroupMessageAnalyzeApi,
  getLiveTestGroupInfoApi
} from "../observer";

/**
 * 获取项目列表
 * @param {*} option
 */
export function getProjectsApi(option: ProjectsDto): Promise<any> {
  return Axios.get(`/api/v1/projects?${qs.stringify(option)}`);
}

/**
 * 更新或创建项目
 * @param {*} option
 */
export function updateOrCreateProjectApi(
  option: UpdateOrCreateProjectDto
): Promise<any> {
  return Axios.post(`/api/v1/project/update-or-create`, option);
}

/**
 * 通过uuid删除项目
 * @param {*} option
 */
export function deleteProjectApi(option: UuIdDto): Promise<any> {
  return Axios.delete(`/api/v1/project/${option.uuid}`);
}

/**
 * 通过uuid克隆项目
 * @param {*} option
 */
export function cloneProjectApi(option: UuIdDto): Promise<any> {
  return Axios.get(`/api/v1/project/${option.uuid}/clone`);
}

/**
 * 项目详情
 * @param {*} option
 */
export function getProjectApi(option: UuIdDto): Promise<any> {
  return Axios.get(`/api/v1/project/${option.uuid}`);
}

/**
 * 项目发布
 * @param {*} option
 */
export function publishProjectApi(option: UuIdDto): Promise<any> {
  return Axios.get(`/api/v1/project/${option.uuid}/publish`);
}

/**
 * 项目开始
 * @param {*} option
 */
export function startProjectApi(option: UuIdDto): Promise<any> {
  return Axios.get(`/api/v1/project/${option.uuid}/start`);
}

/**
 * 项目结束
 * @param {*} option
 */
export function endProjectApi(option: UuIdDto): Promise<any> {
  return Axios.get(`/api/v1/project/${option.uuid}/end`);
}

/**
 * 项目恢复
 * @param {*} option
 */
export function restoreProjectApi(option: UuIdDto): Promise<any> {
  return Axios.get(`/api/v1/project/${option.uuid}/restore`);
}

/**
 * 导入名单
 * @param option
 * @returns
 */
export function importTokensApi(option: ImportTokensDto): Promise<any> {
  const param = new FormData();
  param.append("file", option.file);
  return Axios.post(`/api/v1/project/${option.uuid}/tokens/import`, param, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

/**
 * 获取已上传的Token列表
 * @param {*} option
 */
export function getTokensApi(option: TokensDto): Promise<any> {
  return Axios.get(
    `/api/v1/project/${option.uuid}/tokens?${qs.stringify(option)}`
  );
}

/**
 * 导出名单
 * @param {*} option
 */
export function exportTokensApi(option: UuIdDto): Promise<any> {
  return Axios.get(`/api/v1/project/${option.uuid}/tokens/export`);
}

/**
 *
 * @returns 下载导入模板
 */
export function getTokenTemplate(): Promise<any> {
  return Axios.get(`/api/v1/tokens/template`);
}

/**
 * 获取最后一次导入记录
 * @param {*} option
 */
export function getLastImportTokensRecordApi(option: UuIdDto): Promise<any> {
  return Axios.get(`/api/v1/project/${option.uuid}/tokens/last_import_record`);
}

/**
 * 访谈大纲-会话环节列表
 * @param {*} option
 */
export function getConversationsApi(option: UuIdDto): Promise<any> {
  return Axios.get(`/api/v1/project/${option.uuid}/conversations`);
}

/**
 * 访谈大纲-基础调查列表
 * @param {*} option
 */
export function getOnboardingPollsApi(option: UuIdDto): Promise<any> {
  return Axios.get(`/api/v1/project/${option.uuid}/onboarding-polls`);
}

/**
 * 访谈大纲-段落更新或创建
 * @param {*} option
 */
export function updateOrCreateSectionApi(
  option: UpdateOrCreateSectionDto
): Promise<any> {
  return Axios.post(
    `/api/v1/project/${option.uuid}/section/update-or-create`,
    option
  );
}

/**
 * 访谈大纲-段落组移动
 * @param {*} option
 */
export function moveSectionApi(option: MoveSectionDto): Promise<any> {
  return Axios.post(`/api/v1/project/${option.uuid}/section/move`, option);
}

/**
 * 访谈大纲-移动会话环节消息
 * @param {*} option
 */
export function moveSectionMessageApi(
  option: MoveSectionMessageDto
): Promise<any> {
  return Axios.post(
    `/api/v1/project/${option.uuid}/section/${option.sectionId}/message/move`,
    option
  );
}

/**
 * 访谈大纲-删除段落
 * @param {*} option
 */
export function deleteSectionApi(option: IdsDto): Promise<any> {
  return Axios.delete(
    `/api/v1/project/${option.uuid}/section/${option.sectionId}`
  );
}

/**
 * 访谈大纲-复制段落
 * @param {*} option
 */
export function copySectionApi(option: IdsDto): Promise<any> {
  return Axios.get(
    `/api/v1/project/${option.uuid}/section/${option.sectionId}/copy`
  );
}

/**
 * 多轮随机-测试组-列表
 * @param {*} option
 */
export function testGroupsApi(option: IdsDto): Promise<any> {
  return Axios.get(`/api/v1/project/${option.uuid}/test-groups`);
}

/**
 * 多轮随机-测试组-素材列表
 * /api/v1/project/${option.uuid}/test-group/materials?available=1 除去了已添加组的素材
 * @param {*} option
 */

export function testGroupsMaterialsApi(option: IdsDto): Promise<any> {
  return Axios.get(`/api/v1/project/${option.uuid}/test-group/materials`);
}

/**
 * 多轮随机-测试组-创建或更新
 * @param {*} option
 */
export function updateOrCreateTestGroupApi(option: UpdateOrCreateTestGroupDto): Promise<any> {
  return Axios.post(`/api/v1/project/${option.project_uuid}/test-group/update-or-create`,option);
}

/**
 * 多轮随机-测试组-删除
 * @param {*} option
 */
export function deleteTestGroupApi(option: IdsDto): Promise<any> {
  return Axios.delete(
    `/api/v1/project/${option.uuid}/test-group/${option.sectionId}`
  );
}


/**
 * 多轮随机-访谈大纲-添加会话环节消息到测试组
 * @param {*} option
 */
export function updateSectionTestGroupApi(option: UpdateSectionTestGroupDto): Promise<any> {
  return Axios.post(`/api/v1/project/${option.uuid}/section/${option.sectionId}/message/${option.messageId}/test-group`,option);
}

/**
 * 多轮随机-访谈大纲-测试组-详情
 * @param {*} option
 */
export function getTestGroupInfoApi(option: IdsDto): Promise<any> {
  if(option.observer){
    return getLiveTestGroupInfoApi(option)
  }else{
    return Axios.get(`/api/v1/project/${option.uuid}/test-group/${option.testGroupId}`);
  }
}

/**
 * 多轮随机-访谈大纲-结束测试组
 * @param {*} option
 */
export function stopTestGroupApi(option: IdsDto): Promise<any> {
  return Axios.post(`/api/v1/project/${option.uuid}/test-group/${option.testGroupId}/stop`);
}

/**
 * 多轮随机-访谈大纲-结束当轮测试组
 * @param {*} option
 */
export function stopCurrentTestGroupApi(option: IdsDto): Promise<any> {
  return Axios.post(`/api/v1/project/${option.uuid}/test-group/${option.testGroupId}/stop-round`);
}

/**
 * 多轮随机-测试组-计算配额分组情况
 * @param {*} option
 */
export function computeQuotaGroupApi(option: ComputeQuotaGroupDto): Promise<any> {
  return Axios.post(`/api/v1/project/${option.uuid}/compute-quota-group`,option);
}

/**
 * 基础调查-更新或创建
 * @param {*} option
 */
export function updateOrCreateOnboardingPollApi(
  option: UpdateOrCreateOnboardingPollsDto
): Promise<any> {
  return Axios.post(
    `/api/v1/project/${option.project_uuid}/onboarding-poll/update-or-create`,
    option
  );
}

/**
 * 样本分组-获取列表
 * @param {*} option
 */
export function getSegmentsApi(option: UuIdDto & ObserverDto): Promise<any> {
  return Axios.get(
    `/api/v1/${option.observer ? "observer/live" : "project"}/${
      option.uuid
    }/segments`
  );
}
/**
 * 样本分组-更新或创建
 * @param {*} option
 */
export function updateOrCreateSegmentApi(
  option: UpdateOrCreateSegmentDto
): Promise<any> {
  return Axios.post(
    `/api/v1/project/${option.uuid}/segment/update-or-create`,
    option
  );
}

/**
 * 样本分组-删除
 * @param {*} option
 */
export function deleteSegmentApi(option: UuIdDto & IdDto): Promise<any> {
  return Axios.delete(`/api/v1/project/${option.uuid}/segment/${option.id}`);
}

/**
 * 样本分组-根据选项获取人数
 * @param {*} option
 */
export function getSegmentJoinsByOptionsApi(
  option: GetSegmentJoinsOptionsDto
): Promise<any> {
  return Axios.post(`/api/v1/project/${option.uuid}/segment/joins`, {
    options: option.options,
  });
}

/**
 * 会话消息-设置的更新或创建
 * @param {*} option
 */
export function updateOrCreateSectionMessageSettingApi(
  option: UpdateOrCreateSectionMessageSettingDto
): Promise<any> {
  return Axios.post(
    `/api/v1/project/${option.uuid}/section/${option.sectionId}/message/${option.messageId}/setting/update-or-create`,
    option
  );
}

/**
 * 会话消息-选项更新或创建
 * @param option
 * @returns
 */
export function updateOrCreateSectionMessageOptionApi(
  option: UpdateOrCreateSectionMessageOptionDto
): Promise<any> {
  return Axios.post(
    `/api/v1/project/${option.uuid}/section/${option.sectionId}/message/${option.messageId}/option/update-or-create`,
    option
  );
}

/**
 * 会话消息-选项删除
 * @param {*} option
 */
export function deleteSectionMessageOptionApi(option: IdsDto): Promise<any> {
  return Axios.delete(
    `/api/v1/project/${option.uuid}/section/${option.sectionId}/message/${option.messageId}/option/${option.optionId}`
  );
}

/**
 * 基础调查-详情
 * @param {*} option
 */
export function getOnboardingPollApi(option: UuIdDto & IdDto): Promise<any> {
  return Axios.get(
    `/api/v1/project/${option.uuid}/onboarding-poll/${option.id}`
  );
}

/**
 * 基础调查-删除
 * @param {*} option
 */
export function deleteOnboardingPollApi(option: UuIdDto & IdDto): Promise<any> {
  return Axios.delete(
    `/api/v1/project/${option.uuid}/onboarding-poll/${option.id}`
  );
}

/**
 * 访谈大纲-新增会话环节消息
 * @param {*} option
 */
export function updateOrCreateSectionMessageApi(
  option: UpdateOrCreateSectionMessageDto
): Promise<any> {
  return Axios.post(
    `/api/v1/project/${option.uuid}/section/${option.sectionId}/message/update-or-create`,
    option
  );
}

/**
 * 访谈大纲-查询会话环节消息详情
 * @param {*} option
 */
export function getSectionMessageApi(option: IdsDto): Promise<any> {
  return Axios.get(
    `/api/v1/project/${option.uuid}/section/${option.sectionId}/message/${option.messageId}`
  );
}

/**
 * 访谈大纲-删除会话环节消息
 * @param {*} option
 */
export function deleteSectionMessageApi(option: IdsDto): Promise<any> {
  return Axios.delete(
    `/api/v1/project/${option.uuid}/section/${option.sectionId}/message/${option.messageId}`
  );
}

/**
 * 访谈大纲-复制会话环节消息
 * @param {*} option
 */
export function copySectionMessageApi(option: IdsDto): Promise<any> {
  return Axios.get(
    `/api/v1/project/${option.uuid}/section/${option.sectionId}/message/${option.messageId}/copy`
  );
}

/**
 * 访谈大纲-修改会话环节消息状态
 * @param option
 * @returns
 */
export function changeSectionMessageStatusApi(
  option: ChangeSectionMessageStatusDto
): Promise<any> {
  return Axios.post(
    `/api/v1/project/${option.uuid}/section/${option.sectionId}/message/${option.messageId}/status`,
    option
  );
}
/**
 * 基础调查-移动
 * @param {*} option
 */
export function moveOnboardingPollApi(
  option: MoveOnboardingPollDto
): Promise<any> {
  return Axios.post(
    `/api/v1/project/${option.uuid}/onboarding-poll/move`,
    option
  );
}

/**
 * 基础调查-复制
 * @param {*} option
 */
export function copyOnboardingPollApi(option: UuIdDto & IdDto): Promise<any> {
  return Axios.get(
    `/api/v1/project/${option.uuid}/onboarding-poll/${option.id}/copy`
  );
}

/**
 * 导入excel大纲
 * @param option
 * @returns
 */
export function excelGuideImportApi(option: ExcelGuideDto): Promise<any> {
  const param = new FormData();
  param.append("file", option.file);
  return Axios.post(
    `/api/v1/project/${option.uuid}/excel-guide/import`,
    param,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
}

/**
 * 导出excel大纲
 * @param option
 * @returns
 */
export function excelGuideExportApi(option: UuIdDto): Promise<any> {
  return Axios.get(`/api/v1/project/${option.uuid}/excel-guide/export`, {
    responseType: "blob",
  });
}

/**
 * 访谈大纲-移动会话环节消息-分组移动
 * @param {*} option
 */
export function moveSectionMessageGroupApi(
  option: MoveSectionMessageGroupDto
): Promise<any> {
  return Axios.get(
    `/api/v1/project/${option.uuid}/section/${option.messageId}/message/move/${option.sectionId}/${option.pos}`
  );
}

/**
 * 会话消息-选项的移动
 * @param {*} option
 */
export function moveSectionMessageOptionApi(
  option: MoveSectionMessageOptionDto
): Promise<any> {
  return Axios.post(
    `/api/v1/project/${option.uuid}/section/${option.sectionId}/message/${option.messageId}/option/move`,
    option
  );
}

/**
 * 会话消息-分支的更新和创建
 * @param option
 * @returns
 */
export function updateOrCreateSectionMessageBranchApi(
  option: UpdateOrCreateSectionMessageBranchDto
): Promise<any> {
  return Axios.post(
    `/api/v1/project/${option.uuid}/section/${option.sectionId}/message/${option.messageId}/branch/update-or-create`,
    option
  );
}

/**
 * 会话消息-分支的删除
 * @param option
 * @returns
 */
export function deleteOrCreateSectionMessageBranchApi(
  option: IdsDto
): Promise<any> {
  return Axios.delete(
    `/api/v1/project/${option.uuid}/section/${option.sectionId}/message/${option.messageId}/branch/${option.branchId}`
  );
}

/**
 * 会话消息-复制内容到所有分支
 * @param option
 * @returns
 */
export function copyContentToAllBranchsApi(
  option: CopyContentToAllBranchsDto
): Promise<any> {
  return Axios.post(
    `/api/v1/project/${option.uuid}/section/${option.sectionId}/message/${option.messageId}/branch/copy-content-to-all`,
    option
  );
}

/**
 * 会话消息-会话消息列表轮询接口
 *
 * @param option
 * @returns
 */
export function getConversationMessagesApi(
  option: UuIdDto & DriverDto & StageDto
): Promise<any> {
  return Axios.get(
    `/api/v1/conversations/${option.uuid}/messages?driver=${
      option.driver ?? ""
    }&stage=${option.stage ?? ""}`
  );
}

/**
 * 会话消息-受访者列表轮询接口
 *
 * @param option
 * @returns
 */
export function getConversationRespondentsApi(
  option: UuIdDto 
): Promise<any> {
    return Axios.get(
      `/api/v1/conversations/${option.uuid}/respondents`
    );
}


/**
 * 会话消息-会话消息单题轮询接口
 *
 * @param option
 * @returns
 */
export function getConversationSingleMessagesApi(
  option: UuIdDto & MessageIdDto & DriverDto
): Promise<any> {
  return Axios.get(
    `/api/v1/conversations/${option.uuid}/message-single/${option.messageId}?driver=${option.driver}`
  );
}

/**
 * 访谈大纲-新增会话消息(统一修改或新增)
 * @param {*} option
 */
export function updateOrCreateConversationMessageApi(
  option: UpdateOrCreateConversationMessageDto
): Promise<any> {
  return Axios.post(
    `/api/v1/project/${option.project_uuid}/section/${option.section_id}/message/update`,
    option
  );
}

/**
 * 项目分享-获取分享信息
 */
export function getProjectShareApi(option: IdsDto): Promise<any> {
  return Axios.get(`/api/v1/project/${option.uuid}/share`);
}

/**
 * 项目分享-更新分享信息
 */
export function updateProjectShareApi(
  option: UpdateShareInfoDto
): Promise<any> {
  return Axios.post(`/api/v1/project/${option.project_uuid}/share/update`, {
    status: option.status,
    view_guide: option.view_guide,
    data_export: option.data_export,
    keyword: option.keyword,
    nlp: option.nlp,
    ai: option.ai,
  });
}

/**
 * 分析模块-根据样本分组获取分析结果
 */
export function getMessageSegmentAnalyzeApi(
  option: GetMessageSegmentAnalyzeDto
): Promise<any> {
  if (option.observer) {
    return getLiveMessageSegmentAnalyzeApi(option);
  } else {
    return Axios.get(
      `/api/v1/conversations/${option.uuid}/message-segment/${option.mid}/segment/${option.id}`,
      {
        params: {
          display: option.display,
          branch_id: option.branch_id,
          driver: option.driver,
          analysis_type:option.analysis_type?option.analysis_type:''
        },
      }
    );
  }
}

/**
 * 分析模块-根据Filter选项获取分析结果
 */
export function getMessageOptionsAnalyzeApi(
  option: GetMessageOptionsAnalyzeDto
): Promise<any> {
  if (option.observer) {
    return getLiveMessageOptionsAnalyzeApi(option);
  } else {
    return Axios.post(
      `/api/v1/conversations/${option.uuid}/message-segment/${
        option.mid
      }/segment-options?driver=${option.driver ?? ""}`,
      {
        options: option.options,
        branch_id: option.branch_id,
        display: option.display,
      }
    );
  }
}

/**
 * 分析模块-测试组-消息分析
 */
export function getTestGroupMessageAnalyzeApi(option:TestGroupAnalysisDto):Promise<any> {
  if (option.observer) {
    return getLiveTestGroupMessageAnalyzeApi(option);
  }else{
    return Axios.post(
      `/api/v1/project/${option.uuid}/test-group-analysis/${option.tgid}`
    ,option);
  }
  
}

/**
 * 项目分享协作
 */
export function saveProjectSharedApi(option: {
  uuid: string;
  email: string;
}): Promise<any> {
  return Axios.get(
    `/api/v1/project/${option.uuid}/shared?email=${option.email}`
  );
}

/**
 * 项目分享协作-已邀请的助理主持人
 */
export function getProjectSharedListApi(option: {
  uuid: string;
}): Promise<any> {
  return Axios.get(`/api/v1/project/${option.uuid}/shared-list`);
}

/**
 * 项目分享协作-删除
 */
export function deleteProjectSharedApi(option: {
  uuid: string;
  email: string;
}): Promise<any> {
  return Axios.delete(
    `/api/v1/project/${option.uuid}/shared?email=${option.email}`
  );
}

/**
 * 配额设置列表
 */
export function getProjectQuotaApi(option: IdsDto): Promise<any> {
  return Axios.get(`/api/v1/project/${option.uuid}/quota`);
}

/**
 * 配额设置
 */
export function setProjectQuotaApi(
  option: IdsDto & SetProjectQuotaDto
): Promise<any> {
  return Axios.post(`/api/v1/project/${option.uuid}/quota`, option);
}

/**
 * 配额可选列表
 */
export function getProjectQuotaOnboardingPollApi(option: IdsDto): Promise<any> {
  return Axios.get(`/api/v1/project/${option.uuid}/quota-onboarding-poll`);
}

/**
 * 删除配额
 */
export function deleteQuotaApi(option: IdsDto): Promise<any> {
  return Axios.delete(`/api/v1/project/${option.uuid}/quota/${option.quotaId}`);
}

/**
 * 导出分析数据
 * @param option
 * @returns
 */
export function excelConversationDataExportApi(
  option: UuIdDto & ObserverDto
): Promise<any> {
  return Axios.get(
    `/api/v1/${option.observer ? "observer" : "project"}/${
      option.uuid
    }/conversation-excel`,
    { responseType: "blob" }
  );
}

/**
 * 导出受访者数据
 * @param option
 * @returns
 */
export function excelConversationReplyDataExportApi(
  option: UuIdDto & ObserverDto
): Promise<any> {
  return Axios.get(
    `/api/v1/${option.observer ? "observer" : "project"}/${
      option.uuid
    }/conversation-reply-excel`,
    { responseType: "blob" }
  );
}

/**
 * 获取受访者资源下载信息
 * @param option
 * @returns
 */
export function getConversationReplyDownloadInfoApi(
  option: UuIdDto & ObserverDto
): Promise<any> {
  return Axios.get(
    `/api/v1/${option.observer ? "observer" : "project"}/${
      option.uuid
    }/download-resource`
  );
}

/**
 * 获取受访者Excel导出数据进度
 * @param option
 * @returns
 */
export function getConversationExcelExportStatus(
  option: UuIdDto & ObserverDto
) {
  return Axios.get(
    `/api/v1/${option.observer ? "observer" : "project"}/${
      option.uuid
    }/conversation-excel/status`
  );
}

/**
 * 启动受访者EXCEL导出计算
 */
export function recomputeConversationExcelExport(
  option: UuIdDto & ObserverDto
) {
  // get recompute-export
  return Axios.get(
    `/api/v1/${option.observer ? "observer" : "project"}/${
      option.uuid
    }/conversation-excel/recompute-export`
  );
}

/**
 * 生成公共链接
 * @param option
 * @returns
 */
export function getScanLinkApi(option: UuIdDto): Promise<any> {
  return Axios.get(`/api/v1/project/${option.uuid}/scan-link `);
}

/**
 * NLP-导入项目配置
 * @param option
 * @returns
 */
export function importNlpApi(option: ImportNlpDto): Promise<any> {
  const param = new FormData();
  param.append("file", option.file);
  return Axios.post(`/api/v1/nlp/${option.uuid}/import`, param, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

/**
 * NLP-导出配置模版
 * @param {*} option
 */
export function exportNlpApi(option: UuIdDto): Promise<any> {
  return Axios.get(`/api/v1/nlp/config/export?uuid=${option.uuid}`, {
    responseType: "blob",
  });
}

/**
 * 生成公共链接
 * @param option
 * @returns
 */
export function getNlpConfigDetailApi(option: UuIdDto): Promise<any> {
  return Axios.get(`/api/v1/nlp/config/detail`, {
    params: option,
  });
}

/**
 * 获取时区列表
 * @returns
 */
export function getTimezonesApi(): Promise<any> {
  return Axios.get(`/api/v1/timezones`);
}

/**
 * 获取时区信息
 * @returns
 */
export function getTimezoneApi(option: TimezoneDto): Promise<any> {
  return Axios.get(`/api/v1/timezones?id=${option.id}`);
}

/**
 * 获取ai分析
 * @returns
 */
export function getAnalysisOpenSummaries(option: IdDto): Promise<any> {
  return Axios.put(`/api/v1/ai/analysis/open-ended-summaries/${option.id}`);
}

/**
 * 获取ai图片分析
 * @returns
 */
export function getAnalysisImageTags(option: IdDto): Promise<any> {
  return Axios.put(`/api/v1/ai/analysis/messages/${option.id}/image-tags`);
}


/**
 * 获取ai默认提示词
 * @returns
 */
export function getAiSettingsApi(): Promise<any> {
  return Axios.get(`/api/v1/ai/settings`);
}


/**
 * 获取项目设置
 * @returns
 */
export function getProjectSettingsApi( option: IdsDto): Promise<any> {
  return Axios.get(`/api/v1/project/${option.uuid}/settings`);
}

/**
 * 设置项目设置
 * @returns
 */
export function setProjectSettingsApi( option: IdsDto): Promise<any> {
  return Axios.post(`/api/v1/project/${option.uuid}/settings`, option);
}


