import { LocaleType } from "@/interface/common";
import { QueryPage, UuIdDto, IdDto } from "../model";

export interface IdsDto {
    uuid?: string;
    sectionId?: number;
    messageId?: number;
    optionId?: number;
    branchId?: number;
    quotaId?: number;
    testGroupId?: number;
    observer?: boolean;
    auto_send?: boolean;
}

export enum ProjectFormType {
    Basic = "basic",
    Session = "session",
}

export enum ProjectStatus {
    None = "none",
    Pending = "pending",
    Published = "published",
    Start = "start",
    End = "end",
}

export enum testGroupStatus {
    Done = "done",
    Ready = "ready",
    Testing = "testing",
    Sent = "sent",
    Skipped = "skipped",
}

export enum ProjectStatusName {
    pending = "未开始",
    published = "已发布",
    start = "进行中",
    end = "已结束",
}

export enum ConversationStatusType {
    SENDED = "sended",
    SKIPED = "skiped",
    PENDING = "pending",
}


export enum ProjectTokenStatus {
    None = "none",
    NoStart = "no-start",
    AlreadyStart = "already-start",
    NumberLimit = "number-limit",
    QuotaLimit = "quota-limit",
    NoPartJoin = "no-part-join",
    PartJoin = "part-join",
    FullJoin = "full-join",
}

export enum EntityType {
    speak = "speak",
    poll = "poll",
    poll_multi = "poll_multi",
    picture_poll = "picture_poll",
    picture_poll_multi = "picture_poll_multi",
    ask_opinion = "ask_opinion",
    ask_experience = "ask_experience",
    image = "image",
    video = "video",
    branch = "branch",
    rank = "rank",
    upload_image = "upload_image",
    upload_video = "upload_video",
    upload_mix = "upload_mix",
    onboarding_poll = "onboarding_poll",
    onboarding_poll_multi = "onboarding_poll_multi",
    onboarding_poll_picture = "onboarding_poll_picture",
    onboarding_poll_picture_multi = "onboarding_poll_picture_multi",
    onboarding_ask_opinion = "onboarding_ask_opinion",
}

export enum OrderType {
    None = "none",
    ASC = "asc",
    DESC = "desc",
}
export interface EntityTypeOption {
    icon: string;
    name?: any;
    label?: any;
    msg: any;
    duration?: number;
    value: EntityType;
}

export interface ProjectsDto extends QueryPage {
    sort?: string;
    title?: string;
    status?: ProjectStatus;
    filters?: any;
    primary?: string;
}

export interface UpdateOrCreateProjectDto {
    [key: string]: any;
    project_uuid?: string;
    cover: string;
    estimated_time?: number;
    introduction: string;
    limit_number?: number;
    moderator_cover: string;
    moderator_name: string;
    number?: number;
    title: string;
    project_name: string;
    expect_at?: string | null;
    timezone: string;
    topic: string;
    industry: string;
    company: string;
    research_purpose: string;
    language: string;
}

export interface ImportTokensDto extends UuIdDto {
    file: File;
}

export interface ImportNlpDto extends UuIdDto {
    file: File;
}

export interface TokensDto extends QueryPage, UuIdDto { 
    filters?: any
}

export interface UpdateOrCreateSectionDto {
    uuid: string;
    section_name?: string;
    color?: string;
    guide_id: number;
    id?: number;
    sequence?: number;
}

export interface MoveSectionDto extends UuIdDto {
    data: {
        [key: string]: number;
    };
}

export interface MoveSectionMessageDto extends UuIdDto {
    sectionId: number;
    data: {
        [key: string]: number;
    };
}

export interface UpdateOrCreateOnboardingPollsDto {
    id?: number;
    project_uuid: string;
    guide_id: number;
    sequence?: number;
    message_entity: {

        content: string;
        duration: number;
        entity_type: EntityType;
        link?: string;
        parent_id?: number;
        branchs?: [];
        options?: [];
        settings?: any[];
    };
}

export interface UpdateOrCreateSegmentDto {
    uuid: string;
    id?: number;
    name: string;
    options: Array<{
        id?: number;
        segment_id?: number;
        message_entity_id: number;
        message_option_id: number;
    }>;
}

export interface UpdateOrCreateTestGroupDto {
    project_uuid: string;
    id?: number;
    name: string;
    material_id: number;
    rounds:number;
    quotas: Array<{
        id: number;
        message_entity_id?: number;
        project_uuid: string;
        test_group_id?: number;
    }>;
}

export interface UpdateSectionTestGroupDto {
    uuid?: string;
    sectionId?: number;
    messageId?: number;
    test_group_id:number;
}

export interface ComputeQuotaGroupDto {
    uuid?: string;
    id:number;
    quotas: Array<{
        id: number;
        message_entity_id?: number;
        project_uuid: string;
        test_group_id?: number;
    }>;
}

export interface TestGroupAnalysisDto {
    uuid: string;
    tgid:number;
    branch_id?: string;
    display?: string;
    filter_option_ids?:Array<number>;
    message_entity_id:number;
    message_option_id?:number;
    project_uuid:string;
    segment_id?:number;
    test_group_id:number;
    test_option_id?:number;
    observer?: boolean;
    type:number;
    analysis_type?:string;
}


export interface UpdateOrCreateSectionMessageSettingDto {
    uuid: string;
    sectionId: number;
    messageId: number;
    message_entity_id: number;
    id?: number;
    options: {
        randomized: boolean;
        other: boolean;
        none: boolean;
    };
}

export interface UpdateOrCreateSectionMessageOptionDto {
    uuid: string;
    sectionId: number;
    messageId: number;
    message_entity_id: number;
    sequence?: number;
    id?: number;
    link?: string;
    text?: string;
}

export interface UpdateOrCreateSectionMessageDto {
    uuid: string;
    sectionId: number;
    id?: number;
    content: string;
    duration: number;
    entity_type: EntityType;
    guide_id: number;
    link?: string;
    parent_id?: number;
    sequence?: number;
}

export interface ChangeSectionMessageStatusDto extends IdsDto {
    status: ConversationStatusType;
}

export interface MoveOnboardingPollDto {
    uuid: string;
    data: {
        [key: string]: number;
    };
}


export interface ExcelGuideDto extends UuIdDto {
    file: File;
}

export interface MoveSectionMessageGroupDto {
    uuid: string;
    messageId: number;
    sectionId: number;
    pos: string;
}

export interface MoveSectionMessageOptionDto {
    uuid: string;
    sectionId: number;
    messageId: number;
    data: {
        [key: string]: number;
    };
}

export interface UpdateOrCreateSectionMessageBranchDto {
    uuid: string;
    sectionId: number;
    messageId: number;
    options?: any[];
    content?: string;
    id?: number;
    message_entity_id: number;
    name?: string;
}

export interface UpdateShareInfoDto {
    project_uuid: string;
    status: string;
    view_guide: boolean;
    data_export: boolean;
    keyword: boolean;
    nlp: boolean;
    ai: boolean;
}

export interface CopyContentToAllBranchsDto {
    content: string;
    uuid: string;
    sectionId: number;
    messageId: number;
    message_entity_id: number;
}


export interface UpdateOrCreateConversationMessageDto {
    id?: number;
    project_uuid: string;
    section_id: number;
    guide_id: number;
    sequence?: number;
    message_entity: {
        content: string;
        duration: number;
        entity_type: EntityType;
        link?: string;
        parent_id?: number;
        branchs?: [];
        options?: [];
        settings?: any[];
        nlp_settings?: any;
        ai_settings?: any;
    };
}

export interface GetMessageSegmentAnalyzeDto {
    uuid: string;
    mid: string;
    id: number;
    driver?: string;
    display?: string;
    branch_id?: number;
    observer?: boolean;
    analysis_type?:string;
}

export interface GetMessageOptionsAnalyzeDto {
    uuid: string;
    mid: string;
    options: number[];
    display?: string;
    branch_id?: number;
    observer?: boolean;
    driver?: string;
}

export interface GetSegmentJoinsOptionsDto {
    uuid: string;
    options: Array<{
        message_entity_id: number;
        message_option_id: number;
    }>;
}

export interface SetProjectQuotaDto {
    message_entity_id: number;
    quotas: Array<{ option_id: number; rate: number }>;
}
